import React, { useState } from 'react';
import { Button, Col, Container, Form, Row, Tab, Tabs } from 'react-bootstrap';
import jsyaml from "js-yaml";
import ReactHtmlParser from 'react-html-parser';
import Select from 'react-select';
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import './App.css';

// Replaces all :emoji: in the given text with matching emoji images
function emojify(text, emojis) {
  return text.replace(/:([\w+-]+):/gm, function(original, name) {
    if (emojis[name]) {
      return `<img style="max-height: 21px; max-width: 21px; vertical-align: bottom;" src=${emojis[name]} alt=":${name}:" title=":${name}:" />`;
    } else {
      return original;
    }
  });
}

function random_ident() {
    let result = "";
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let counter = 0;
    while (counter < 5) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
      counter += 1;
    }
    return "web-" + result;
}

// Build the emoji data lookup table with alias support
// 'panda_face' => 'https://..'
function buildEmojiLookupTable(json) {
  var emojis = {};

  json.forEach(function(emoji) {
    emojis[emoji.name] = emoji.url;

    if (emoji.aliases) {
      emoji.aliases.forEach(function(alias) {
        emojis[alias] = emoji.url;
      });
    }
  });

  return emojis;
}

export default function App(props) {
  const [pipeline, setPipeline] = useState([]);
  const [result, setResult] = useState(false);
  const [emojis, setEmojis] = useState([]);
  //const [targetBranch, setTargetBranch] = useState("");
  //const [hash, setHash] = useState("");
  let [key, setKey] = useState("nightly");
  const [error, setError] = useState("");
  let [explainConfig, setExplainConfig] = useState([])
  const [token, setToken] = React.useState(localStorage.getItem("buildkite-api-token") || "");
  const urlParams = new URLSearchParams(window.location.search);
  const referer = document.referrer.match(/https:\/\/github.com\/MaterializeInc\/materialize\/pull\/([0-9]+)/) ? document.referrer : "";
  const [pr, setPR] = React.useState(urlParams.get("pr") || referer);
  const [initial, setInitial] = useState(true);
  const [heapProfile, setHeapProfile] = useState(false);
  const [sanitizer, setSanitizer] = useState({value: 'none', label: 'None'});
  const [priority, setPriority] = useState(0);

  const prHandle = async (pr) => {
    const found = pr.match(/(https:\/\/github.com\/MaterializeInc\/materialize\/pull\/|#)?([0-9]+)/);
    let branch = "";
    let hash = "";
    let pull_number = "";
    if (found) {
      pull_number = found[2];
      const url = `https://api.github.com/repos/MaterializeInc/materialize/pulls/${pull_number}`
      const response = await fetch(url, {
        headers: {
          "Accept": "application/vnd.github+json",
          "X-GitHub-Api-Version": "2022-11-28"
        }});

      if (!response.ok) {
        setError(`Couldn't get PR: ${await response.text()}`);
        return;
      }

      const content = await response.json();

      branch = content.head.label;
      hash = content.head.sha;
    }

    document.getElementById("branch").value = branch;
    document.getElementById("hash").value = hash;
    document.getElementById("pull_number").value = pull_number;
    handleChange();
  }

  const handlePRChange = async (event) => {
    const pr = document.getElementById("pr").value;
    setPR(pr);
    prHandle(pr);
  }

  if (((urlParams.get("pr") && urlParams.get("pr") === pr) || (referer !== "" && referer === pr)) && initial) {
    prHandle(pr);
    setInitial(false);
  }


  const handleChange = async (event) => {
    const hash = document.getElementById("hash").value;
    if (hash.length !== 40) {
      setError("Hash should be 40 chars");
      setPipeline([]);
      return;
    } else if (!hash.match(/^[0-9a-f]+$/i)) {
      setError("Hash should contain hex characters only");
      setPipeline([]);
      return;
    }

    setResult(false)

    if (emojis.length === 0) {
      const url = "https://api.buildkite.com/v1/accounts/buildkite-docs-examples/emojis?access_token=da704c13c66f5e93f43a425689970474312cc6d7";
      window.fetch(url).then(function(response) { return response.json() }).then(buildEmojiLookupTable).then((e) => {setEmojis(e); });
    }

    let dir = "";
    if (key === "coverage") {
      dir = "test";
    } else {
      dir = key;
    }

    const url = `https://raw.githubusercontent.com/MaterializeInc/materialize/${hash}/ci/${dir}/pipeline.template.yml`
    const response = await fetch(url);

    if (!response.ok) {
      // Make sure the commit hash is still correct
      if (document.getElementById("hash").value === hash) {
        setError(`URL couldn't load: ${await response.text()}`);
        setPipeline([]);
      }
      return;
    }

    try {
      const yaml = jsyaml.load(await response.text());
      let steps = [];

      for (const step of yaml.steps) {
        if ("group" in step) {
          console.log(step.group);
          if ("label" in step) {
            step.group = step.label;
            delete step.label;
          }
          let current_step = Object.assign({}, step);
          current_step.steps = [];
          for (let inner_step of step.steps) {
            if ("id" in inner_step) {
              if (inner_step.id !== "coverage-pr-analyze" && inner_step.id !== "analyze" && inner_step.id !== "build-x86_64" && inner_step.id !== "build-aarch64" && inner_step.id !== "build-wasm" && !("skip" in inner_step) && !(key === "coverage" && "coverage" in inner_step && inner_step.coverage === "skip") && !(key === "test" && "coverage" in inner_step && inner_step.coverage === "only")) {
                if (!("label" in inner_step)) {
                  inner_step.label = inner_step.id;
                }
                inner_step.group = current_step.group;
                current_step.steps.push(inner_step);
              }
            } else if ("command" in inner_step) {
              inner_step.id = inner_step.command;
              if (!("label" in inner_step)) {
                inner_step.label = inner_step.command;
              }
              inner_step.group = current_step.group;
              current_step.steps.push(inner_step);
            }
          }
          if (current_step.steps.length > 0) {
            steps.push(current_step);
            for (const inner_step of current_step.steps) {
              steps.push(inner_step);
            }
          }
        } else if ("id" in step) {
          if (step.id !== "coverage-pr-analyze" && step.id !== "analyze" && step.id !== "build-x86_64" && step.id !== "build-aarch64" && step.id !== "build-wasm" && !("skip" in step) && !(key === "coverage" && "coverage" in step && step.coverage === "skip") && !(key === "test" && "coverage" in step && step.coverage === "only")) {
            if (!("label" in step)) {
              step.label = step.id;
            }
            steps.push(step)
          }
        } else if ("command" in step) {
          if (step.command !== "bin/ci-builder run stable bin/pyactivate -m materialize.ci_util.trim_pipeline nightly" && step.command !== "bin/ci-builder run stable bin/pyactivate -m materialize.ci_util.trim_pipeline release-qualification") {
            step.id = step.command;
            if (!("label" in step)) {
              step.label = step.command;
            }
            steps.push(step)
          }
        }
      }

      // Make sure the commit hash is still correct
      if (document.getElementById("hash").value === hash) {
        setError("");
        setPipeline(steps);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const hash = event.target.hash.value;
    let branch = event.target.branch.value || "main"
    if (branch === "main") {
      branch = random_ident();
    }
    const pull_number = event.target.pull_number.value;
    const message = event.target.message.value;
    const token = event.target.token.value;
    const buildkite_tag = event.target.buildkite_tag.value;
    let test_selection = explainConfig.join(",");
    if (test_selection.length === 0) {
      test_selection = pipeline.filter((e) => "id" in e).map((e) => e.id).join(",");
    }

    let branch_with_suffix = branch;
    if (sanitizer.value !== "none") {
      branch_with_suffix += "_" + sanitizer.value;
    }

    let extra_args = {};
    for (const item of pipeline) {
      const elem = document.getElementById("extra-args-" + item.label);
      if (item.label && "plugins" in item && elem.style.visibility === "visible") {
        for (const plugin of item.plugins) {
          if ("./ci/plugins/mzcompose" in plugin && elem.value) {
            extra_args[item.id] = elem.value;
          }
        }
      }
    }

    if (token.length > 0) {
      const url = `https://api.buildkite.com/v2/organizations/materialize/pipelines/${key}/builds`

      let env = {
        "CI_TEST_SELECTION": test_selection,
        "BUILDKITE_PULL_REQUEST": pull_number,
        "CI_HEAP_PROFILES": heapProfile ? 1 : 0,
        "CI_SANITIZER": sanitizer.value,
        "CI_PRIORITY": priority,
        "CI_EXTRA_ARGS": JSON.stringify(extra_args),
      };

      if (buildkite_tag !== "")
        env["BUILDKITE_TAG"] = buildkite_tag;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
          "commit": hash,
          "branch": branch_with_suffix,
          "message": message,
          "ignore_pipeline_branch_filters": true,
          "env": env
        })
      });

      if (!response.ok) {
        setError(`Couldn't trigger build: ${await response.text()}`);
        return;
      }

      const content = await response.json();
      setResult(content)
      window.open(content.web_url);
    } else {
      let url = `https://buildkite.com/materialize/${key}/builds?branch=${encodeURIComponent(branch_with_suffix)}&commit=${encodeURIComponent(hash)}&message=${encodeURIComponent(message)}&env=CI_TEST_SELECTION=${test_selection}%0ABUILDKITE_PULL_REQUEST=${pull_number}%0ACI_HEAP_PROFILES=${heapProfile ? 1 : 0}%0ACI_SANITIZER=${encodeURIComponent(sanitizer.value)}%0ACI_PRIORITY=${encodeURIComponent(priority)}%0ACI_EXTRA_ARGS=${encodeURIComponent(JSON.stringify(extra_args))}`;
      if (buildkite_tag !== "")
        url = `${url}%0ABUILDKITE_TAG=${encodeURIComponent(buildkite_tag)}`;
      url = `${url}#new`;
      window.open(url);
    }
  }

  const handleTokenChange = (event) => {
    const value = event.target.value;
    if (value.startsWith("bkua_") && value.length === 45) {
      localStorage.setItem("buildkite-api-token", value)
    }
    setToken(value);
  }

  const handleHeapProfileChange = (event) => {
    if (event.target.checked) {
      setHeapProfile(true);
    } else {
      setHeapProfile(false);
    }
  }

  const options = [
    { value: 'none', label: 'None' },
    { value: 'address', label: 'Address + Debug Assertions' },
    { value: 'hwaddress', label: 'HW Address (aarch64-only)' },
    { value: 'cfi', label: 'Control Flow Integrity' },
    { value: 'thread', label: 'Thread' },
    { value: 'leak', label: 'Leak' },
    { value: 'undefined', label: 'Undefined Behavior (C/C++ only)' },
  ];

  const commit_selection = (
    <Container id="header">
      <p id="source-code"><a href="https://github.com/MaterializeInc/trigger-ci">Source code</a></p>
      <h1>Trigger <a href="https://github.com/MaterializeInc/materialize">Materialize</a> CI <a href="https://buildkite.com/materialize/">Builds</a></h1>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}><a href="https://github.com/MaterializeInc/materialize/pulls">Pull Request</a></Form.Label>
        <Col sm={5}>
          <Form.Control type="input" id="pr" placeholder="24198, #24198 or https://github.com/MaterializeInc/materialize/pull/24198" onChange={handlePRChange} value={pr} autoFocus />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}>Target Branch</Form.Label>
        <Col sm={5}>
          <Form.Control type="input" id="branch" placeholder="random identifier if unset" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}><a href="https://github.com/MaterializeInc/materialize/commits/main/">Commit</a></Form.Label>
        <Col sm={5}>
          <Form.Control type="input" id="hash" placeholder="full SHA" onChange={handleChange} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}>PR #</Form.Label>
        <Col sm={5}>
          <Form.Control type="input" id="pull_number" placeholder="" onChange={handleChange} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}>Message</Form.Label>
        <Col sm={5}>
          <Form.Control type="input" id="message" placeholder="optional" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}><a href="https://buildkite.com/user/api-access-tokens/new?description=trigger-ci&scopes[]=write_builds">Buildkite API Token</a></Form.Label>
        <Col sm={5}>
          <Form.Control type="password" id="token" placeholder="optional, needs write_builds scope on Materialize organization" onChange={handleTokenChange} value={token} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Col sm={12}>
          <Form.Check key="all" type="switch" id="all">
            <Form.Check.Input checked={heapProfile} onChange={handleHeapProfileChange} />
            <Form.Check.Label>Collect continuous <a href="https://www.notion.so/analyzing-pprof-for-a-release-build-3fa5a68aef994d90b3c94bca6eea4da8">heap profiles</a></Form.Check.Label>
          </Form.Check>
        </Col>
        <p/>
        <Form.Label column sm={2}><a href="https://doc.rust-lang.org/beta/unstable-book/compiler-flags/sanitizer.html">Sanitizer</a></Form.Label>
        <Col sm={5}>
          <Select defaultValue={options[0]} onChange={setSanitizer} options={options} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}><a href="https://buildkite.com/docs/pipelines/managing-priorities">Priority</a></Form.Label>
        <Col sm={1}>
          <Form.Control value={priority} />
        </Col>
        <Col sm={4}>
          <RangeSlider value={priority} onChange={e => setPriority(e.target.value)} min={-100} max={100} tooltip='off' />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}><a href="https://github.com/MaterializeInc/materialize/blob/875b4b68e0bc31cddd3948809d47f254b6c0ebda/ci/test/build.py#L120-L185">Buildkite Tag (upload debuginfo to S3)</a></Form.Label>
        <Col sm={4}>
          <Form.Control type="input" id="buildkite_tag" placeholder="optional" />
        </Col>
      </Form.Group>
    </Container>
  );

  let test_selection = (<Container></Container>);

  if (pipeline.length > 0) {
    const handleConfigChange = (event) => {
      let list = explainConfig;
      for (const id of event.target.id.split(",")) {
        if (id.length === 0) {
          continue;
        } else if (event.target.checked) {
          list = [...list, id];
        } else {
          list = list.filter(option => option !== id);
        }
      }
      setExplainConfig(list);
    }

    const handleAllChange = (event) => {
      if (event.target.checked) {
        setExplainConfig(pipeline.filter((e) => "id" in e).map((e) => e.id));
      } else {
        setExplainConfig([]);
      }
    }

    const handleKeyChange = (firstTab, lastTab) => {
      setKey(firstTab);
      setExplainConfig([]);
      // super hacky
      key = firstTab;
      explainConfig = [];
      handleChange();
    }

    test_selection = (
      <Container id="pipeline-selection">
      <Row>
        <Col>
          <Tabs id="key" activeKey={key} onSelect={handleKeyChange}>
            <Tab eventKey="test" title="Test">
            </Tab>
            <Tab eventKey="coverage" title="Coverage">
            </Tab>
            <Tab eventKey="nightly" title="Nightly">
            </Tab>
            <Tab eventKey="release-qualification" title="Release Qualification">
            </Tab>
            <Tab eventKey="slt" title="SLT">
            </Tab>
            <Tab eventKey="security" title="Security">
            </Tab>
            <Tab eventKey="qa-canary" title="QA Canary">
            </Tab>
          </Tabs>
        </Col>
      </Row>
      <Form.Group id="trigger-build" as={Row} className="mb-3" controlId="submit">
        <p></p>
        <Col sm={12}>
          <Button type="submit">Trigger Build</Button>{' '}
          <Form.Text>
            {result ? (<a href={result.web_url}>{result.pipeline.name} #{result.number}</a>) : error}
          </Form.Text>
        </Col>
        <p></p>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="explainConfig">
        <Form.Check key="all" type="switch" id="all">
          <Form.Check.Input checked={pipeline.filter((item) => "id" in item).every((item) => explainConfig.includes(item.id))} onChange={handleAllChange} />
          <Form.Check.Label>All</Form.Check.Label>
        </Form.Check>
        {pipeline.map((item) => (
          <Form.Check key={item.id || "," + item.steps.map((e) => e.id)} type="switch" id={item.id || "," + item.steps.map((e) => e.id)}>
            <Row>
            <Col sm={item.label && item.group ? 2 : 1}></Col>
            <Col sm={item.label && item.group ? 5 : 6}>
              <Form.Check.Input checked={explainConfig.includes(item.id) || ("steps" in item && item.steps.every((e) => explainConfig.includes(e.id)))} onChange={handleConfigChange} />
              <Form.Check.Label>
                {ReactHtmlParser(emojify(item.label || item.group, emojis))}
              </Form.Check.Label>
            </Col>
            <Col sm={4}>
              <input className="form-control" style={{ "fontSize": "90%", "padding": "0.01em", "visibility": (explainConfig.includes(item.id) && item.label && "plugins" in item && item.plugins.map((plugin) => "./ci/plugins/mzcompose" in plugin).some(Boolean)) ? "visible" : "hidden" }} type="input" id={"extra-args-" + item.label} placeholder="extra args" />
            </Col>
            </Row>
          </Form.Check>
        ))}
      </Form.Group>
      </Container>
    );
  }
  return (
    <Form className="trigger-form" onSubmit={handleSubmit}>
      {commit_selection}
      {test_selection}
      <Container>
        <Form.Text>{error}</Form.Text>
      </Container>
    </Form>
  );
}
